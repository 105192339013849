import React from 'react';
import GoogleIcon from '@/components/icons/Google.icon';
import { roboto } from '@/themes/customizations';
import { Button, SxProps } from '@mui/material';

function SignInWithGoogle({ onClick, sx = {} }: { onClick: () => void; sx: SxProps }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        px: '8dp',
        fontFamily: roboto.style.fontFamily,
        fontWeight: 'medium',
        color: ({ palette }) => (palette.mode === 'dark' ? '#E3E3E3' : '#1F1F1F'),
        bgcolor: ({ palette }) => (palette.mode === 'dark' ? '#131314' : '#FFFFFF'),
        gap: '24dp',
        border: ({ palette }) =>
          palette.mode === 'dark' ? '1px #8E918F solid' : '1px #747775 solid',
        textTransform: 'unset',
        boxShadow: 2,
        ...sx,
      }}
    >
      <GoogleIcon sx={{ marginRight: '.5rem' }} />
      <span>Continue with Google</span>
    </Button>
  );
}

export default SignInWithGoogle;
