import React, { ComponentProps } from 'react';
import { Control, Controller } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';

interface PhoneTextFieldProps extends ComponentProps<typeof TextField> {
  control: Control<any>;
}

function PhoneTextfield({
  required = false,
  error,
  control,
  name,
  label = 'Phone number',
  sx = {},
  disabled = false,
  tabIndex = undefined,
  helperText = '',
  variant = 'filled',
}: PhoneTextFieldProps) {
  return (
    <FormControl
      variant={variant}
      fullWidth
      error={error}
      disabled={disabled}
      tabIndex={tabIndex}
      sx={{
        '& .PhoneInput': {
          width: '100% !important',
          maxWidth: 'unset !important',
          gap: '0.5rem',
          px: '.5rem',
          borderBottom: ({ palette }) => `1px solid ${palette.common.borderTwo}`,
          borderRadius: '3px 3px 0px 0px',
          paddingTop: '1rem',
          height: '56px',
          '& input': {
            color: disabled ? 'text.disabled' : 'text.primary',
          },
          ...(variant === 'filled'
            ? {
                bgcolor: 'common.bg',
              }
            : variant === 'outlined'
            ? {
                border: ({ palette }) => `1px solid ${palette.common.border}`,
                borderRadius: '5px',
                px: '.8rem',
              }
            : {}),
        },
        ...sx,
      }}
    >
      <InputLabel
        sx={{
          paddingLeft: '2.5rem',
          transform: 'translate(-22px, 4px) scale(0.75)',
        }}
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>

      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          <PhoneInputWithCountry
            countryCallingCodeEditable={false}
            international
            control={control}
            ref={ref}
            defaultCountry={'CH'}
            rules={{ required: required }}
            disabled={disabled}
            {...field}
          />
        )}
      />
      {(!required || helperText) && (
        <FormHelperText id={`${name}-helper-text`}>
          {helperText + ' '}
          {!required ? 'Optional' : ''}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default PhoneTextfield;
